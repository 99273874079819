<template>
  <v-card outlined>
    <v-card-title>Répartition par heures</v-card-title>
    <v-card-text>
      <loader v-if="loading" />
      <v-container v-else-if="resultText.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <v-container v-else>
        {{ resultText }}
        <v-list outlined>
          <v-list-item v-for="item in resultDetails" :key="item.day">
            <v-list-item-content class="d-block">
              <v-list-item-title class="text-sm">
                <v-icon size="20">
                  {{ item.hourSlot.icon }}
                </v-icon>
                {{ item.hourSlot.value }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-2xl font-weight-semibold">
                {{ item.percent.display }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import {
  mdiAlarm,
  mdiCoffeeMaker,
  mdiMonitorMultiple,
  mdiSilverwareForkKnife,
  mdiLaptop,
  mdiCupcake,
  mdiExitRun,
  mdiGlassCocktail,
  mdiWeatherNight,
} from "@mdi/js";
import { noDataText } from "@/utils/constants";
import { formatPercent } from "@/utils/formatting";

import Loader from "@/components/common/Loader";

import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";

const timeSlots = {
  5: {
    value: "A l'aube (avant 6h)",
    icon: mdiWeatherNight,
  },
  7: {
    value: "Tôt le matin (6h - 8h)",
    icon: mdiAlarm,
  },
  9: {
    value: "En début de matinée (8h - 10h)",
    icon: mdiCoffeeMaker,
  },
  11: {
    value: "En fin de matinée (10h - midi)",
    icon: mdiMonitorMultiple,
  },
  13: {
    value: "Entre midi et deux",
    icon: mdiSilverwareForkKnife,
  },
  15: {
    value: "En début d'après-midi (14h - 16h)",
    icon: mdiLaptop,
  },
  17: {
    value: "En fin d'après-midi (16h - 18h)",
    icon: mdiCupcake,
  },
  19: {
    value: "En début de soirée (18h - 20h)",
    icon: mdiExitRun,
  },
  21: {
    value: "Le soir (après 20h)",
    icon: mdiGlassCocktail,
  },
};

export default {
  name: "RepartitionByHoursChart",
  components: {
    Loader,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      loading: false,
      noDataText,
      resultText: "",
      resultDetails: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    resetData() {
      this.resultText = "";
      this.resultDetails = [];
    },
    getSlotDisplayValue(timeSlot) {
      switch (true) {
        case ["0", "1", "2", "3", "4", "5"].includes(timeSlot):
          return timeSlots["5"];

        case ["6", "7"].includes(timeSlot):
          return timeSlots["7"];

        case ["8", "9"].includes(timeSlot):
          return timeSlots["9"];

        case ["10", "11"].includes(timeSlot):
          return timeSlots["11"];

        case ["12", "13"].includes(timeSlot):
          return timeSlots["13"];

        case ["14", "15"].includes(timeSlot):
          return timeSlots["15"];

        case ["16", "17"].includes(timeSlot):
          return timeSlots["17"];

        case ["18", "19"].includes(timeSlot):
          return timeSlots["19"];

        case ["20", "21", "22", "23"].includes(timeSlot):
        default:
          return timeSlots["21"];
      }
    },
    formatResult: (self, data) => {
      const repartition = Object.entries(data).reduce(
        (acc, [timeSlot, percent]) => {
          const timeSlotValue = self.getSlotDisplayValue(timeSlot);
          const timeSlotIndex = acc.findIndex(
            (obj) => obj.hourSlot === timeSlotValue
          );

          if (timeSlotIndex > -1) {
            const cumulatedPercent =
              (acc[timeSlotIndex]?.percent.raw ?? 0) +
              Math.round(percent * 100);

            acc[timeSlotIndex] = {
              hourSlot: timeSlotValue,
              percent: {
                raw: cumulatedPercent,
                display: formatPercent(cumulatedPercent / 100, 0),
              },
            };

            return [...acc];
          }

          return [
            ...acc,
            {
              hourSlot: timeSlotValue,
              percent: {
                raw: Math.round(percent * 100),
                display: formatPercent(percent, 0),
              },
            },
          ];
        },
        []
      );

      const sortedRepartition = [...repartition].sort(
        (a, b) => b.percent.raw - a.percent.raw // desc
      );

      let nbTimeSlots = 0,
        percent = 0;
      for (const hourSlot of sortedRepartition) {
        nbTimeSlots++;
        percent += hourSlot.percent.raw;

        if (nbTimeSlots > 4) {
          return {
            text: "La fréquentation est plutôt équilibrée tout au long de la journée : ",
            details: repartition,
          };
        } else if (percent > 50) {
          const details = sortedRepartition.slice(0, nbTimeSlots);
          if (nbTimeSlots > 1) {
            return {
              text: `La fréquentation se concentre principalement (${formatPercent(
                percent / 100,
                0
              )}) sur les tranches suivantes : `,
              details,
            };
          }

          return {
            text: `La fréquentation se concentre principalement (${formatPercent(
              percent / 100,
              0
            )}) sur la tranche suivante : `,
            details,
          };
        }
      }
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.sites &&
        this.sites.length > 0 &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          sites_id: this.sites.join(","),
          keyword: this.searchText,
          ...(this.useMarmitonFilters
            ? {
                ...(this.ingredientsToInclude
                  ? { ingredients: this.ingredientsToInclude }
                  : {}),
                ...(this.flavour ? { flavour: this.flavour } : {}),
                ...(this.region ? { region: this.region } : {}),
                ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
              }
            : {}),
        };
        this.loading = true;

        try {
          const { data } = await this.axiosGet(
            "/regie/visitor-info/by-hours",
            params
          );

          const { text, details } = this.formatResult(this, data);
          this.resultText = text;
          this.resultDetails = details;

          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none transparent;
}
</style>
