var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold text-base"},[_vm._v("Statistiques")])]),_c('v-card-text',[(_vm.loading)?_c('loader'):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"circle"},[_c('div',{staticClass:"circle-content"},[_c('strong',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.unique_visitors.value))]),_c('br'),_vm._v(" Visiteurs Uniques ")])])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"4","lg":"4","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1 mt-1",class:{ female: _vm.sex_ratio.raw < 0.5 },attrs:{"size":"44","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHumanMaleFemale)+" ")])],1),_c('div',{staticClass:"ms-3"},[(_vm.sex_ratio.raw > 0.5)?_c('p',{staticClass:"text-base mb-0"},[_vm._v(" Majorité d'hommes ")]):(_vm.sex_ratio.raw == 0.5)?_c('p',{staticClass:"text-base mb-0"},[_vm._v(" Hommes / Femmes ")]):_c('p',{staticClass:"text-base mb-0"},[_vm._v("Majorité de femmes")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-2xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.sex_ratio.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.sex_ratio.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"4","lg":"4","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1 mt-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMonitorCellphone)+" ")])],1),_c('div',{staticClass:"ms-3"},[(_vm.device_ratio.raw > 0.5)?_c('p',{staticClass:"text-base mb-0"},[_vm._v(" Majorité Desktop ")]):(_vm.device_ratio.raw == 0.5)?_c('p',{staticClass:"text-base mb-0"},[_vm._v(" Desktop / Mobile ")]):_c('p',{staticClass:"text-base mb-0"},[_vm._v("Majorité Mobile")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-2xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.device_ratio.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.device_ratio.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"4","lg":"4","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1 mt-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMonitorEye)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base mb-0"},[_vm._v("Vues")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-2xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.views.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.views.raw))])])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","lg":"4","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1 mt-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMonitorEye)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base mb-0"},[_vm._v("Sessions")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-2xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.sessions.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.sessions.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"4","lg":"4","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1 mt-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSpeedometer)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base mb-0"},[_vm._v("Durée moy / Sessions")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-2xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.session_duration.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.session_duration.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"4","lg":"4","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1 mt-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSpeedometer)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base mb-0"},[_vm._v("Vues / Session")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-2xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.views_per_session.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.views_per_session.raw))])])],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }