<template>
  <div>
    <v-text-field
      outlined
      dense
      label="Contenant"
      persistent-hint
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      v-model="textFilter"
    >
    </v-text-field>
  </div>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "TextFilter",
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {};
  },
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: `${this.department}/updateContainsText`,
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.textFilter,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    textFilter: {
      get() {
        return this.$store.getters[`${this.department}/getContainsText`];
      },
      set(e) {
        this.$store.dispatch(`${this.department}/updateContainsText`, e);
      },
    },
  },
  watch: {
    textFilter(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        URL_PARAM_NAMES[this.$options.name],
        newValue
      );
    },
  },
};
</script>

<style scoped></style>
