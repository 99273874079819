<template>
  <v-card outlined>
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistiques</span>
    </v-card-title>
    <v-card-text>
      <loader v-if="loading" />
      <v-row v-else>
        <v-col cols="12" md="3">
          <div class="circle">
            <div class="circle-content">
              <strong class="text--primary">{{ unique_visitors.value }}</strong>
              <br />
              Visiteurs Uniques
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <v-row class="mt-1">
            <v-col cols="4" lg="4" md="12">
              <div class="d-flex">
                <v-avatar
                  size="44"
                  rounded
                  class="elevation-1 mt-1"
                  v-bind:class="{ female: sex_ratio.raw < 0.5 }"
                >
                  <v-icon dark color="white" size="30">
                    {{ icons.mdiHumanMaleFemale }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-base mb-0" v-if="sex_ratio.raw > 0.5">
                    Majorité d'hommes
                  </p>
                  <p class="text-base mb-0" v-else-if="sex_ratio.raw == 0.5">
                    Hommes / Femmes
                  </p>
                  <p class="text-base mb-0" v-else>Majorité de femmes</p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text--primary text-2xl font-weight-bold"
                        v-on="on"
                        v-bind="attrs"
                        >{{ sex_ratio.value }}</span
                      >
                    </template>
                    <span>{{ sex_ratio.raw }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="4" lg="4" md="12"
              ><div class="d-flex">
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1 mt-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ icons.mdiMonitorCellphone }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-base mb-0" v-if="device_ratio.raw > 0.5">
                    Majorité Desktop
                  </p>
                  <p class="text-base mb-0" v-else-if="device_ratio.raw == 0.5">
                    Desktop / Mobile
                  </p>
                  <p class="text-base mb-0" v-else>Majorité Mobile</p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text--primary text-2xl font-weight-bold"
                        v-on="on"
                        v-bind="attrs"
                        >{{ device_ratio.value }}</span
                      >
                    </template>
                    <span>{{ device_ratio.raw }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="4" lg="4" md="12">
              <div class="d-flex">
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1 mt-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ icons.mdiMonitorEye }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-base mb-0">Vues</p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text--primary text-2xl font-weight-bold"
                        v-on="on"
                        v-bind="attrs"
                        >{{ views.value }}</span
                      >
                    </template>
                    <span>{{ views.raw }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" lg="4" md="12">
              <div class="d-flex">
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1 mt-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ icons.mdiMonitorEye }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-base mb-0">Sessions</p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text--primary text-2xl font-weight-bold"
                        v-on="on"
                        v-bind="attrs"
                        >{{ sessions.value }}</span
                      >
                    </template>
                    <span>{{ sessions.raw }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="4" lg="4" md="12">
              <div class="d-flex">
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1 mt-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ icons.mdiSpeedometer }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-base mb-0">Durée moy / Sessions</p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text--primary text-2xl font-weight-bold"
                        v-on="on"
                        v-bind="attrs"
                        >{{ session_duration.value }}</span
                      >
                    </template>
                    <span>{{ session_duration.raw }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="4" lg="4" md="12">
              <div class="d-flex">
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1 mt-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ icons.mdiSpeedometer }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-base mb-0">Vues / Session</p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="text--primary text-2xl font-weight-bold"
                        v-on="on"
                        v-bind="attrs"
                        >{{ views_per_session.value }}</span
                      >
                    </template>
                    <span>{{ views_per_session.raw }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import {
  mdiHumanMaleFemale,
  mdiMonitorCellphone,
  mdiMonitorEye,
  mdiSpeedometer,
} from "@mdi/js";
import { runParallelAsyncs } from "@/utils/async";
import {
  formatNumberCompact,
  formatPercent,
  formatTime,
} from "@/utils/formatting";

import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";
import Loader from "@/components/common/Loader";

const emptyValue = {
  value: 0,
  raw: 0,
};

export default {
  name: "TopStatsCard",
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      icons: {
        mdiSpeedometer,
        mdiMonitorCellphone,
        mdiMonitorEye,
        mdiHumanMaleFemale,
      },
      unique_visitors: { ...emptyValue },
      sex_ratio: { ...emptyValue },
      device_ratio: { ...emptyValue },
      views: { ...emptyValue },
      sessions: { ...emptyValue },
      session_duration: { ...emptyValue },
      views_per_session: { ...emptyValue },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    resetData() {
      // Update final props as object won't re-render otherwise :
      this.unique_visitors.value = 0;
      this.unique_visitors.raw = 0;
      this.sex_ratio.value = 0;
      this.sex_ratio.raw = 0;
      this.device_ratio.value = 0;
      this.device_ratio.raw = 0;
      this.views.value = 0;
      this.views.raw = 0;
      this.sessions.value = 0;
      this.sessions.raw = 0;
      this.session_duration.value = 0;
      this.session_duration.raw = 0;
      this.views_per_session.value = 0;
      this.views_per_session.raw = 0;
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.sites &&
        this.sites.length > 0 &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          sites_id: this.sites.join(","),
          keyword: this.searchText,
          ...(this.useMarmitonFilters
            ? {
                ...(this.ingredientsToInclude
                  ? { ingredients: this.ingredientsToInclude }
                  : {}),
                ...(this.flavour ? { flavour: this.flavour } : {}),
                ...(this.region ? { region: this.region } : {}),
                ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
              }
            : {}),
        };
        this.loading = true;

        try {
          const {
            statsResponse: {
              data: { activeUsers, views, sessions, avg_session_duration },
            },
            genderResponse: {
              data: { male },
            },
            deviceResponse: {
              data: { desktop },
            },
          } = await runParallelAsyncs({
            statsResponse: this.axiosGet("/regie/visitor-info/stats", params),
            genderResponse: this.axiosGet(
              "/regie/visitor-info/stats/gender",
              params
            ),
            deviceResponse: this.axiosGet(
              "/regie/visitor-info/stats/device",
              params
            ),
          });

          this.unique_visitors.raw = activeUsers;
          this.unique_visitors.value = formatNumberCompact(
            this.unique_visitors.raw
          );

          this.sex_ratio.raw = male;
          this.sex_ratio.value = formatPercent(
            this.sex_ratio.raw > 0.5
              ? this.sex_ratio.raw
              : 1 - this.sex_ratio.raw
          );

          this.device_ratio.raw = desktop;
          this.device_ratio.value = formatPercent(
            this.device_ratio.raw > 0.5
              ? this.device_ratio.raw
              : 1 - this.device_ratio.raw
          );

          this.views.raw = views;
          this.views.value = formatNumberCompact(this.views.raw);

          this.sessions.raw = sessions;
          this.sessions.value = formatNumberCompact(this.sessions.raw);

          this.session_duration.raw = avg_session_duration;
          this.session_duration.value = formatTime(
            Math.round(this.session_duration.raw)
          );

          this.views_per_session.raw = views / Math.max(sessions, 1);
          this.views_per_session.value = formatNumberCompact(
            this.views_per_session.raw
          );

          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  background-color: var(--v-primary-base);
  border-color: var(--v-primary-base);

  &.female {
    background-color: #d11b88;
    border-color: #d11b88;
  }
}
.circle {
  position: relative;
  width: 60%;
  max-width: 200px;
  border-radius: 50%;
  border: 4px solid var(--v-primary-base);
  margin: auto;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .circle-content {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 25%;
    font-size: 1rem;

    strong {
      font-size: 2.125rem;
    }
  }
}
</style>
