var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pdfContainer",staticClass:"pdfContainer"},[(_vm.logo)?_c(_vm.logo,{tag:"component"}):_vm._e(),_c('p',{staticClass:"text-2xl mb-6"},[_vm._v("Infos visiteurs")]),_c('v-card',{staticClass:"mb-6 pa-2",attrs:{"outlined":""}},[_c('filters',{attrs:{"department":_vm.department}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('top-stats-card',{attrs:{"department":_vm.department}})],1)],1),(['regie'].includes(_vm.department))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('repartition-by-sites-chart',{attrs:{"department":_vm.department}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('top-articles',{attrs:{"department":_vm.department}})],1)],1),(['marmiton'].includes(_vm.department))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('top-ingredients-table',{staticClass:"mb-6",attrs:{"startDate":_vm.startDate,"endDate":_vm.endDate,"department":_vm.department,"outlined":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('repartition-by-ages-chart',{attrs:{"department":_vm.department}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('repartition-by-regions-chart',{attrs:{"department":_vm.department}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('repartition-by-days-chart',{attrs:{"department":_vm.department}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('repartition-by-hours-chart',{attrs:{"department":_vm.department}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('views-by-day',{staticClass:"mb-6",attrs:{"title":"Vues / Jours","department":_vm.department}})],1)],1),_c('dot-menu',{attrs:{"position":"page-top-right","isLoading":_vm.isLoading,"pdfButton":{
      getContainerRef: function () { return _vm.$refs.pdfContainer; },
      filename: 'regie_info_utilisateurs.pdf',
      disabled: _vm.disabled,
    },"excelButton":{
      excelData: _vm.excelData,
      filename: "regie_info_utilisateurs.xlsx",
      disabled: _vm.disabled,
    }},on:{"exportExcel":_vm.exportExcel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }