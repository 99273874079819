<template>
  <div>
    <v-row class="d-flex ma-3" v-if="['regie'].includes(department)">
      <v-col cols="6">
        <site-group-filter store="regie" />
      </v-col>
      <v-col cols="6">
        <site-filter store="regie" />
      </v-col>
      <v-col cols="3">
        <date-filter :store="department" periodType="past-only" range="14" />
      </v-col>
      <v-col cols="6">
        <text-filter
          :menu-props="{ maxHeight: '300', zIndex: 13 }"
          :department="department"
          class="mt-2"
        ></text-filter>
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="disabled" outlined color="secondary" />
      </v-col>
    </v-row>
    <v-row class="d-flex ma-3" v-else>
      <v-col cols="3">
        <date-filter :store="department" periodType="past-only" range="14" />
      </v-col>
      <v-col cols="3">
        <text-filter
          class="mt-2"
          :department="department"
          :menu-props="{ maxHeight: '300', zIndex: 13 }"
        ></text-filter>
      </v-col>
      <v-col cols="3">
        <ingredient-filter
          class="mt-2"
          label="Ingrédients"
          :withGroups="false"
          storeGetter="getMarmitonIngredientsToInclude"
          storeUpdater="updateMarmitonIngredientsToInclude"
        />
      </v-col>
      <v-col cols="3">
        <flavour-filter />
      </v-col>
      <v-col cols="3">
        <age-filter />
      </v-col>
      <v-col cols="3">
        <region-filter />
      </v-col>
      <v-col cols="6" class="d-flex">
        <v-spacer />
        <apply-filters
          :disabled="disabled"
          outlined
          :store="department"
          color="secondary"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import IngredientFilter from "@/components/regie/common/filters/IngredientFilter";
import AgeFilter from "@/components/regie/visitors-info/filters/AgeFilter";
import FlavourFilter from "@/components/regie/visitors-info/filters/FlavourFilter.vue";
import RegionFilter from "@/components/regie/visitors-info/filters/RegionFilter.vue";
import TextFilter from "@/components/regie/visitors-info/filters/TextFilter.vue";

export default {
  name: "Filters",
  components: {
    AgeFilter,
    ApplyFilters,
    DateFilter,
    FlavourFilter,
    IngredientFilter,
    RegionFilter,
    SiteFilter,
    SiteGroupFilter,
    TextFilter,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
  computed: {
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    disabled() {
      return (
        isEmpty(this.sites) ||
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10 ||
        this.isLoading
      );
    },
  },
};
</script>

<style></style>
